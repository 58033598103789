<template>
  <div>
    <PageHeader title="A.I. Tools" sub-title="GPT-powered tools for automated marketing.">
    </PageHeader>

    <gm-page-container>
      <!-- Loading pulse -->
      <gm-grid v-if="toolsLoading">
        <gm-card loading v-for="i in totalAvailableToolsCount" :key="i" />
      </gm-grid>
      <gm-grid v-if="!toolsLoading">
        <gm-card
          v-for="(tool, idx) in aiTools"
          :key="idx"
          :disabled="tool.status !== 'live'"
          clickable
          @click.prevent="tool.status === 'live' ? openTool(tool) : null"
        >
          <div class="px-4 py-5 sm:p-6">
            <!-- Content goes here -->
            <a
              href="#"
              @click.prevent="openTool(tool)"
              class="text-xl font-semibold mb-2 hover:text-brand-500"
              >{{ tool.name }}</a
            >
            <p class="mt-2 text-sm line-clamp-3 text-gray-500">{{ tool.description }}</p>
          </div>
          <template #footer>
            <div class="flex w-full px-4 py-4 sm:px-6 align-bottom place-content-between">
              <div class="flex items-end overflow-hidden mr-3">
                <span
                  :class="[
                    'inline-flex items-center rounded-full  px-2.5 py-0.5 text-xs font-medium  mr-2',
                    tool.status === 'live'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800',
                  ]"
                >
                  <span
                    v-if="tool.status === 'live'"
                    class="mr-2 -ml-1 bg-green-500 w-2 h-2 rounded-full"
                  ></span>
                  <span v-if="tool.status === 'live'">{{ tool.version }}</span>
                  <span v-else>In development</span>
                </span>
              </div>

              <gm-button
                :disabled="tool.status !== 'live'"
                secondary
                :label="tool.status === 'live' ? 'Load tool' : 'Coming soon'"
                v-if="tool.status === 'live'"
                @click.stop="openTool(tool)"
              />
            </div>
          </template>
        </gm-card>
      </gm-grid>
    </gm-page-container>
  </div>
</template>

<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import PageContainer from "@/components/ui/PageContainer.vue";
import PageHeader from "@/components/PageHeader.vue";
const toolsLoading = ref(true);
let toolsList = ref([]);
let totalAvailableToolsCount = computed(() => {
  return toolsList.value.length > 0 ? toolsList.value.length : 1;
});

const router = useRouter();

let aiTools = reactive([]);

function openTool(tool) {
  router.push(`/ai-tools/${tool.path.replace("_", "-")}`);
}

async function fetchListOfTools() {
  try {
    const response = await fetch(`${process.env.VUE_APP_AI_API_URL}/api/tools/list`);
    const data = await response.json();
    toolsList.value = data.data;
  } catch (err) {
    console.error("Error fetching tools:", err);
  }
}

async function retrieveAiTools() {
  // For each tool in ToolsList, fetch the details from the AI API
  try {
    for (const tool of toolsList.value) {
      const response = await fetch(`${process.env.VUE_APP_AI_API_URL}/api/tools/${tool.path}/info`);
      if (response.status === 200) {
        const data = await response.json();
        if (data.data) {
          aiTools.push(data.data);
        }
      }
    }
  } catch (err) {
    console.error("Error fetching tools:", err);
  } finally {
    toolsLoading.value = false;
  }
}

onMounted(async () => {
  router.push({ query: null });
  await fetchListOfTools();
  await retrieveAiTools();
});
</script>
